<!-- eslint-disable prettier/prettier -->
<template>
  <section class="testimonial-container">
    <div class="container">
      <div class="row">
        <h6 class="heading">{{ $store.state.translation.Home.software_services }}</h6>
        <h1 class="main-heading">{{ $store.state.translation.Home.software_services_des }}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <carousel :items-to-show="1" :breakpoints="breakpoints">
          <slide v-for="item in testimonial" :key="item">
            <div class="testimonial-card">
              <div class="col-md-12">
                <div class="card d-flex mx-auto">
                  <div class="card-image">
                    <img class="img-fluid d-flex mx-auto" :src="item.img" />
                  </div>
                  <div class="card-text">
                    <div class="card-title">{{ item.title }}</div>
                    {{ item.des }}
                  </div>
                  <a href="#contact" class="footer">
                    <span id="name">{{ item.name }}<br /></span>
                  </a>
                </div>
              </div>
            </div>
          </slide>

          <!-- <template #addons>
            <navigation />
            <pagination />
          </template> -->
        </carousel>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
export default {
  name: "AppTestimonial",
  components: {
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },
  data() {
    return {
      serviceDetail:{},
      breakpoints: {
        640: {
          itemsToShow: 1.5,
        },
        768: {
          itemsToShow: 2,
        },
        1024: {
          itemsToShow: 3,
        },
      },
      testimonial: [
        {
          img: this.$store.state.translation.Home.software_service_image_1,
          title: this.$store.state.translation.Home.software_service_title_1,
          des: this.$store.state.translation.Home.software_service_des_1,
          name: this.$store.state.translation.Home.software_service_name_1,
        },
        {
          img: this.$store.state.translation.Home.software_service_image_2,
          title: this.$store.state.translation.Home.software_service_title_2,
          des: this.$store.state.translation.Home.software_service_des_2,
          name: this.$store.state.translation.Home.software_service_name_2,
        },
        {
          img: this.$store.state.translation.Home.software_service_image_3,
          title: this.$store.state.translation.Home.software_service_title_3,
          des: this.$store.state.translation.Home.software_service_des_3,
          name: this.$store.state.translation.Home.software_service_name_3,
        },
        // {
        //   img: this.$store.state.translation.Home.software_service_image_4,
        //   title: this.$store.state.translation.Home.software_service_title_4,
        //   des: this.$store.state.translation.Home.software_service_des_4,
        //   name: this.$store.state.translation.Home.software_service_name_4,
        // },
        // {
        //   img: this.$store.state.translation.Home.software_service_image_5,
        //   title: this.$store.state.translation.Home.software_service_title_5,
        //   des: this.$store.state.translation.Home.software_service_des_5,
        //   name: this.$store.state.translation.Home.software_service_name_5,
        // },
        // {
        //   img: this.$store.state.translation.Home.software_service_image_6,
        //   title: this.$store.state.translation.Home.software_service_title_6,
        //   des: this.$store.state.translation.Home.software_service_des_6,
        //   name: this.$store.state.translation.Home.software_service_name_6,
        // },
        
      ],
    };
  },
  
};
</script>
<!-- eslint-disable prettier/prettier -->
<style>
.testimonial-container{
    padding: 40px;
    background: #d7e3e3;
}
.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  padding: 10px;
}
.testimonial-container .card {
  border-radius: 1rem;
  /* box-shadow: 0px -10px 0px rgb(151, 248, 6); */
}
@media (max-width: 767px) {
  .card {
    margin: 1rem 0.7rem 1rem;
    max-width: 80vw;
  }
  .testimonial-card{
    padding: 5px;
  }
}
.testimonial-container .card-image{
    height: 350px;
    width: 100%;
    border-radius: 4px;
}
.testimonial-container .card img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.testimonial-container .card-title {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
.testimonial-container .card-text {
  text-align: center;
  padding: 0 15px;
  font-size: 12px;
  color: rgb(82, 81, 81);
  margin-top: 15px;
}
.footer {
  border-top: none;
  text-align: center;
  line-height: 1.2rem;
  padding: 2rem 0 1.4rem 0;
  text-decoration: none;
  color: #000;
}
#name {
  font-size: 12px;
  font-weight: bold;
}
#position {
  font-size: 0.7rem;
}
</style>
