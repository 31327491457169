<!-- eslint-disable prettier/prettier -->
<template>
  <section class="product-wrapper" id= "product">
    <div class="container">
      <div class="row">
        <h6 class="heading">{{ $store.state.translation.Home.products }}</h6>
        <div class="product-container">
          <p>
            {{ $store.state.translation.Home.products_description }}
          </p>
          <div class="row" >
            <div :class="{'col-md-7': index == 0 || index == 4, 'col-6 col-md-5': index == 1 || index == 3, 'col-md-12': index === 2}" v-for="(item,  index) in $store.state.productList" :key="index">
             <router-link :to="'/product/' + item.slug" class="prod-cards" :style="{background: item.color}">
              <div class="prod-inner-card " >
                <h3>{{ item.title }}</h3>
                <router-link :to="'/product/' + item.slug"><img src="../../assets/img/Next.png" alt="next" class="img" /></router-link>
              </div>
              <div class="prod-icon" v-if="index== 0">
                <img src="../../assets/img/Combo_Chart.png"/>
              </div>
              <div class="prod-icon" v-if="index== 1">
                <img src="../../assets/img/MessageBot.png"/>
              </div>
              <div class="prod-icon" v-if="index== 2">
                <img src="../../assets/img/Shopping_Cart.png"/>
              </div>
             </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  name: "ProductList",
};
</script>
<!-- eslint-disable prettier/prettier -->
<style scoped>
.prod-icon img{
  width: 66px;
}
.product-images{
  width: 100% !important;
  height: 100%;
}
.product-images img{
  width: 100% !important;
  height: 100% !important;
}
.product-wrapper {
  padding-bottom: 60px;
  padding-top: 40px;
}
.product-container {
  width: 85% !important;
  margin: 0 auto;
}
.product-container p {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 0 50px;
}
.prod-cards {
  padding: 35px;
  border-radius: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.prod-inner-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-1 {
  background: #feffc2;
}
.card-2 {
  background: #ffe3b8;
}
.card-3 {
  background: #d8f6ff;
}
.card-4 {
  background: #fee2ff;
}
.card-5 {
  background: #deffd6;
}
.prod-cards h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.prod-cards .img {
  width: 25px;
}
@media (max-width: 479px) and (min-width: 320px) {

  .product-container{
    width: 98% !important;
  }
  .product-container .row > div {
    width: 100% !important;
  }
  .product-container p{
    padding: 0;
  }
}
</style>
