<template>
  <div>
    <Hero />
    <About />
    <!-- <GetStarted /> -->
    <TalentNetwork />
    <!-- <ClientReview /> -->
    <Testimonials />
    <ProductList />
    <OurTeam />
    <ContactUs />
  </div>
</template>

<script>
import Hero from "./Hero";
import About from "./about";
// import GetStarted from "./getStart.vue";
import TalentNetwork from "./talentNetwork.vue";
// import ClientReview from "./clientReview.vue";
import ProductList from "./productList.vue";
import OurTeam from "./ourTeam.vue";
import ContactUs from "./contact.vue";
import Testimonials from "./testimonials.vue";
export default {
  name: "FrontPage",
  components: {
    Hero,
    About,
    // GetStarted,
    TalentNetwork,
    // ClientReview,
    ProductList,
    OurTeam,
    ContactUs,
    Testimonials,
  },
};
</script>

<style lang="scss" scoped></style>
