<!-- eslint-disable prettier/prettier -->
<template>
  <section class="about-wrapper" id="about">
    <div class="container">
      <div class="row">
        <hr class="about-hr" />
        <div class="col-md-6 about-col">
          <h6 class="heading">{{ $store.state.translation.Home.about }}</h6>
          <div class="about-left-content">
            <h2>{{ $store.state.translation.Home.about_title }}</h2>
            <p v-html="$store.state.translation.Home.about_description"></p>
          </div>
        </div>
        <div class="col-md-6 about-col">
          <div class="about-right-content">
            <a href="#product" class="about-right-inner">
              <div>
                <div class="about-icon experience">
                  <img src="../../assets/img/EXPERIENCE.png" alt="EXPERIENCE" />
                </div>
              </div>
              <div>
                <h3 class="exp">{{ $store.state.translation.Home.about_experince }}</h3>
                <p>
                  {{ $store.state.translation.Home.about_experince_detail }}
                </p>
              </div>
            </a>
            <a href="#our-team" class="about-right-inner">
              <div>
                <div class="about-icon satisfy">
                  <img
                    src="../../assets/img/SATISFACTION.png"
                    alt="SATISFACTION"
                  />
                </div>
              </div>
              <div>
                <h3 class="sat">{{ $store.state.translation.Home.about_satisfaction }}</h3>
                <p>
                  {{ $store.state.translation.Home.about_satisfaction_detail }}
                </p>
              </div>
            </a>
            <a href="#service" class="about-right-inner">
              <div>
                <div class="about-icon creative">
                  <img src="../../assets/img/CREATIVITY.png" alt="CREATIVITY" />
                </div>
              </div>
              <div>
                <h3 class="creativity">{{ $store.state.translation.Home.about_creativity }}</h3>
                <p>
                  {{ $store.state.translation.Home.about_creativity_detail }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  name: "WizzAbout",
};
</script>
<!-- eslint-disable prettier/prettier -->
<style scoped>
.about-wrapper {
  padding: 50px 0;
}
.about-hr {
  color: #f1a208;
  border-top: 2px solid #f1a208;
  opacity: unset;
  padding-bottom: 50px;
  width: 99%;
  margin: 0 auto;
}
.about-wrapper .heading {
  margin: 0;
  color: #7608a9;
  /* padding: 5px 0; */
  font-size: 14px;
  text-align: left !important;
}
.about-left-content {
  width: 90%;
}
.about-left-content h2 {
  margin: 5px 0;
  font-size: 24px;
}
.about-left-content p {
  margin: 10px 0;
  font-size: 14px;
  color: #9b9696;
  font-weight: 500;
}

/* right side content  */
.about-right-inner {
  display: flex;
  width: 90%;
  margin-top: 30px;
}
.about-right-content a{
  text-decoration: none;
}
.about-right-content .about-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
}
.experience {
  border: 3px solid #eb8220;
}
.satisfy {
  border: 3px solid #26a2aa;
}
.creative {
  border: 3px solid #c045c2;
}
.about-right-content .about-icon img {
  width: 40px;
}
.about-right-content h3 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 18px !important;
}
.about-right-content .exp {
  color: #eb8220;
}
.about-right-content .creativity {
  color: #c045c2;
}
.about-right-content .sat {
  color: #26a2aa;
}
.about-right-content p {
  margin: 0;
  font-size: 14px;
  color: #9b9696;
}
@media (max-width: 479px) and (min-width: 320px) {
  .about-hr {
    width: 90%;
  }
}
</style>
