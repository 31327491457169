<!-- eslint-disable prettier/prettier -->
<template>
  <section class="product-banners">
    <!-- <div class="product-slider" v-if="$route.fullPath =='/product/2'">
      <div class="container">
        <div class="row">
          <div class="product-silde">
            <carousel :items-to-show="1">
              <slide v-for="slide in 1" :key="slide">
                <div class="prod-card" :style="{background: productDetail.color}">
                  <h3>{{productDetail.title}}</h3>
                  <div class="product-images" v-if="productDetail.image">
                    <img :src="productDetail.image" alt="e-com" />
                  </div>
                  <div class="product-images" v-else>
                    <img src="../../assets/img/e-com.gif" alt="e-com" />
                  </div>
                  <router-link v-if="!productDetail.image" :to="'/product/' + productDetail.slug"><img src="../../assets/img/Next.png" alt="next" /></router-link>
                </div>
              </slide>

              <template #addons>
            <navigation />
         
          </template>
            </carousel>
          </div>
        </div>
      </div>
    </div> -->
    <div class="main-banner">
      <div class="product-images" v-if="productDetail.image">
        <img :src="productDetail.image" alt="e-com" />
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  data() {
    return {
      productDetail: {},
    };
  },
  methods: {
    filterProduct() {
      const { id } = this.$route.params;
      const filter = this.$store.state.productList.find((i) => i.slug == id);
      this.productDetail = filter;
    },
  },
  mounted() {
    this.filterProduct();
  },
};
</script>
<!-- eslint-disable prettier/prettier -->
<style>
.main-banner{
  padding-bottom: 40px;
}
.product-images {
  width: 100% !important;
  height: 100%;
}
.product-images img {
  width: 100% !important;
  height: 100% !important;
}
.product-slider {
  padding: 40px 0;
  background: #fbf8f8;
}
.product-silde {
  width: 78% !important;
  margin: 0 auto;
}
.prod-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 35px;
  border-radius: 20px;
  margin-top: 30px;
  width: 100%;
  /* margin: 0 10px; */
}

.prod-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.prod-card img {
  width: 25px;
}
.carousel__prev {
  left: -50px;
}
.carousel__next {
  right: -50px;
}
.carousel__prev,
.carousel__next {
  background: #620483;
  color: #fff;
  top: 52%;
  font-size: 14px;
}
.carousel__prev:hover,
.carousel__next:hover {
  background: #000;
  color: #fff;
}
</style>
