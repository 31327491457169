<template>
  <div id="app">
    <div
      v-if="show"
      :class="[{ el: !$store.state.loading }, 'main-loader']"
      @transitionend="onTransitionEnd"
    >
      <div>
        <img src="./assets/img/logo.png" alt="Logo" />
      </div>
    </div>
    <div v-if="!$store.state.loading">
      <AppHeader />
      <div class="main">
        <router-view />
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/header/AppHeader";
import AppFooter from "@/components/footer/AppFooter";
import AOS from "aos";
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      show: true,
    };
  },
  created() {
    this.getWizzData();
    AOS.init();
  },

  methods: {
    onTransitionEnd() {
      this.show = false;
    },
    getWizzData() {
      this.$store.commit("setLoading", true);
      fetch(
        "https://script.google.com/macros/s/AKfycbxe0oH6-fDKUueZAoHk_M7NpbTpN8yx4V5sYgne72306ZVUxzyNzkUKy9dG8nSBy_nY/exec"
      )
        .then((res) => res.json())
        .then((response) => {
          this.$store.commit("setTranslationData", response);
          this.$store.commit("setLoading", false);
          const lang = localStorage.getItem("lang");
          this.$store.dispatch("setLanguage", lang || "en");
          if (!lang) {
            window?.languageChange();
          }
        })
        .catch(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style>
.el {
  opacity: 0 !important;
}

#app {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #282c3f;
}
body {
  margin: 0;
}
</style>
