<!-- eslint-disable prettier/prettier -->
<template>
  <section class="talent-network" id= "service">
    <div class="container">
      <div class="row">
        <h6 class="heading">{{ $store.state.translation.Home.our_services }}</h6>
        <h1 class="main-heading">{{ $store.state.translation.Home.services_title }}</h1>
      </div>
    </div>
    <div class="network-wrapper">
      <div class="mid-section">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img src="../../assets/img/front-end.png" alt="Front-End" />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name1 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail1 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img src="../../assets/img/back-end.png" alt="back-End" />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name2 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail2 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img src="../../assets/img/designer.png" alt="Designers" />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name3 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail3 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img
                    src="../../assets/img/software-eng.png"
                    alt="Software Engineers"
                  />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name4 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail4 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img
                    src="../../assets/img/app-developer.png"
                    alt="App Developers"
                  />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name5 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail5 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
            <div class="col-md-4">
              <div class="network-card" data-aos="zoom-in" data-aos-duration="1000">
                <div class="net-icon">
                  <img
                    src="../../assets/img/mern.png"
                    alt="MERN Stack Developers"
                  />
                </div>
                <div class="network-text">
                  <h3>{{ $store.state.translation.Home.services_name6 }}</h3>
                  <p>{{ $store.state.translation.Home.services_detail6 }}</p>
                  <!-- <a href="#">{{ $store.state.translation.Home.read_more }}</a> -->
                </div>
              </div>
            </div>
            <!-- card  -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {};
</script>
<!-- eslint-disable prettier/prettier -->
<style scoped>
.talent-network {
  padding: 40px 0;
}
.talent-network h6 {
  text-align: center;
}
.talent-network h1 {
  text-align: center;
  font-size: 24px;
  /* margin: 8px 0; */
}
.network-wrapper {
  margin: 20px 0;
  width: 100%;
  min-height: 600px;
  position: relative;
}
.network-wrapper .col-md-4 {
  display: flex;
  justify-content: center;
}

.mid-section {
  background: linear-gradient(
    to bottom,
    #fff 0%,
    #fff 28%,
    #f1f1f1 0%,
    #f1f1f1 90%,
    #fff 0%,
    #fff 25%
  );
}
/* card  */
.network-card {
  border: 1px solid #7901b1;
  border-radius: 24px;
  padding: 15px;
  position: relative;
  z-index: 9;
  /* top: -66%; */
  width: 240px;
  height: 232px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  background: #fff;
}
.network-card .net-icon {
  position: absolute;
  top: -42px;
}
.network-card .net-icon img {
  width: 80px;
}
.network-card .network-text {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.network-card .network-text h3 {
  text-align: center;
  font-size: 18px;
  margin: 0;
}
.network-card .network-text p {
  text-align: center;
  font-size: 14px;
  color: #cec8c8;
  margin: 10px 0;
}
.network-card .network-text a {
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 479px) and (min-width: 320px) {
  .network-wrapper{
    min-height: 100%;
  }
}
</style>
