<!-- eslint-disable prettier/prettier -->
<template>
  <section class="feature">
    <div class="container">
      <div class="row">
        <div class="feature-head">
          <h6>{{productDetail.feature}}</h6>
          <p>
            {{productDetail.product_feature_description}}
          </p>
        </div>
        <div class="feature-container">
          <div class="feature-left features">
            <p> {{productDetail.product_feature_1_des}}</p>
            <p> {{productDetail.product_feature_3_des}}</p>
          </div>
          <div class="feature-middle features">
            <div class="middle-inner">
              <div class="box-border" :style="{borderTop:` 12px solid ${productDetail.color}`, borderLeft:` 12px solid ${productDetail.color}`}">
                <div class="box">
                  <p> {{productDetail.product_feature_1}}</p>
                </div>
              </div>
              <div class="empty-border">
                <div class="box">
                  <p> {{productDetail.product_feature_2}}</p>
                </div>
              </div>
              <div class="empty-border2">
                <div class="box">
                  <p> {{productDetail.product_feature_3}}</p>
                </div>
              </div>
              <div class="box-border2" :style="{borderBottom:` 12px solid ${productDetail.color}`, borderRight:` 12px solid ${productDetail.color}`}">
                <div class="box">
                  <p> {{productDetail.product_feature_4}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-right features">
            <p> {{productDetail.product_feature_2_des}}</p>
            <p> {{productDetail.product_feature_4_des}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
   data() {
        return {
            productDetail:{}
        }
    },
    methods: {
        filterProduct(){
            const { id } = this.$route.params;
            const filter = this.$store.state.productList.find(i => i.slug == id);
            this.productDetail = filter
        }
    },
    mounted() {
        this.filterProduct()
    },
};

</script>
<!-- eslint-disable prettier/prettier -->
<style>
.feature {
  padding-bottom: 250px;
}
.feature .feature-head {
  width: 50%;
  margin: 0 auto;
}
.feature .feature-head h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  color: #000000;
}
.feature .feature-head p {
  font-size: 14px;
  margin: 0;
  text-align: center;
  color: #b2abab;
  padding: 20px 0;
  font-weight: 500;
}
.feature-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 140px 0;
}
.feature-container .features {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}
.feature-left {
  align-items: flex-end !important;
  position: relative;
  right: 0px;
}
.feature-right {
  position: relative;
  top: 70px;
}
.feature-right p:last-child {
  position: relative;
  left: -50px;
}

.feature-container .features p {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  margin: 0;
  color: #000;
}
.middle-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transform: rotate(45deg);
  margin-top: 25px;
}
.middle-inner .box {
  /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25); */
  border: 1px solid #efeaea;
  box-shadow: 6px -2px 20px 7px rgb(0 0 0 / 13%);
  background: #fff;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin: 8px; */
}
.box-border {
  border-top: 12px solid #e4b5ea;
  border-left: 12px solid #e4b5ea;
  position: relative;
  right: 10px;
}
.box-border .box {
  margin: 10px 0px 0px 10px;
}
.box-border2 .box {
  margin: 0 10px 10px 0;
}
.box-border2 {
  border-bottom: 12px solid #e4b5ea;
  border-right: 12px solid #e4b5ea;
}
.empty-border {
  border-top: 12px solid transparent;
  border-right: 12px solid transparent;
  position: relative;
  left: 3px;
  top: -3px;
  bottom: 10px;
}
.empty-border2 {
  border-bottom: 12px solid transparent;
  border-left: 12px solid transparent;
  position: relative;
  right: 13px;
  top: 13px;
}
.middle-inner .box p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #000000;
  text-align: center;
  transform: rotate(-45deg);
}
@media (max-width: 479px) and (min-width: 320px) {
  .feature-container {
    flex-direction: column;
    overflow: hidden;
  }
  .feature .feature-head {
    width: 100%;
  }
  .feature-container .features {
    width: 90%;
  }
  .middle-inner .box {
    width: 100px;
    height: 100px;
  }
  .feature-left {
    flex-direction: row !important;
    height: 130px !important;
    align-items: flex-start !important;
  }
  .feature-right {
    flex-direction: row !important;
    height: 130px !important;
  }
  .feature-right p:last-child {
    left: 0;
  }
}
</style>
