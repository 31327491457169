<!-- eslint-disable prettier/prettier -->
<template>
  <section class="bg-color">
    <div class="container">
      <div class="row">
        <div class="main-wrapper d-flex justify-content-between col-xl">
          <div class="map">
            <img src="../../assets/img/map.png" alt="" />
            <div class="location-drop" @mouseover="overHandler()">
              <img
                src="../../assets/img/location.png"
                alt=""
                style="width: 22px"
              />
              <div>
                <div class="address-detail">
                  <div class="line"></div>
                  <div class="fade-in">
                    <div class="location-text">
                      {{ $store.state.translation.Home.location_1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="location-drop" @mouseover="overHandler()">
              <img src="../../assets/img/location.png" alt="" />
              <div>
                <div :class="['address-detail', { active: active }]">
                  <div class="fade-in">
                    <div class="location-text">
                      {{ $store.state.translation.Home.location_2 }}
                    </div>
                  </div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div class="location-drop" @mouseover="overHandler()">
              <img src="../../assets/img/location.png" alt="" />
              <div>
                <div class="address-detail">
                  <div class="line"></div>
                  <div class="fade-in">
                    <div class="location-text">
                      {{ $store.state.translation.Home.location_3 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex justify-content-between set-width">
            <div>
              <ul>
                <li>
                  <h1>{{ $store.state.translation.Home.about }}</h1>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_about_us
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_how_works
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_clients
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_services
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{ $store.state.translation.Home.projects }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{ $store.state.translation.Home.contact_us }}</router-link>
                </li>
              </ul>
            </div>
            <div class="company">
              <ul>
                <li>
                  <h1>{{ $store.state.translation.Home.footer_company }}</h1>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_vision
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_focus
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_team
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/">{{
                    $store.state.translation.Home.footer_network
                  }}</router-link>
                </li>
              </ul>
              <div class="faq">
                <router-link to="/">{{ $store.state.translation.Home.footer_faq }}</router-link>
                <router-link to="/">{{ $store.state.translation.Home.footer_help }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- last section -->
      <div class="row">
        <div class="main-footer d-flex justify-content-between col-xl">
          <div>
            <div>
              <div class="contact-info">
                <span class="contact"
                  >{{ $store.state.translation.Home.footer_phone }}</span
                >
               <span v-if="$store.state.translation.Home.footer_phone"> | </span>
                <a :href="'mailto:' + $store.state.translation.Home.wizz_redirect_mail">{{ $store.state.translation.Home.footer_email }}</a>
              </div>
              <div class="social-icon d-flex">
                <div>
                  <router-link to="/"
                    ><img src="../../assets/img/LinkedIn2.png" alt="LinkedIn"
                  /></router-link>
                </div>
                <div>
                  <router-link to="/"
                    ><img src="../../assets/img/Twitter.png" alt="Twitter"
                  /></router-link>
                </div>
                <div>
                  <router-link to="/"
                    ><img src="../../assets/img/Instagram.png" alt="Instagram"
                  /></router-link>
                </div>
                <div>
                  <router-link to="/"
                    ><img src="../../assets/img/Facebook.png" alt="Facebook"
                  /></router-link>
                </div>
              </div>
            </div>
            <div>
              <div class="contact-info">
                <router-link to="/">{{
                  $store.state.translation.Home.footer_privacy_policy
                }}</router-link>
                |
                <router-link to="/">{{
                  $store.state.translation.Home.footer_terms_conditions
                }}</router-link>
              </div>
              <div class="copy-right">
                <p>
                  &#169; {{ $store.state.translation.Home.footer_copy_right }}
                  <br />
                  &nbsp; &nbsp;
                  {{ $store.state.translation.Home.footer_all_reserved }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="set-width">
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">{{
                  $store.state.translation.Home.footer_newsletter
                }}</label>
                <input
                  type="email"
                  class="form-control"
                  :placeholder="
                    $store.state.translation.Home.footer_email_placeholder
                  "
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      active: true,
    };
  },
  methods: {
    overHandler() {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.bg-color {
  background: #0f0e0e;
  padding: 80px 0;
}
.main-wrapper {
  align-items: center;
}
.main-wrapper .list ul {
  list-style-type: none;
  padding: 0;
  line-height: 2;
}
.main-wrapper .list ul li h1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.main-wrapper .list ul li a {
  text-decoration: none;
  font-size: 12px;
  color: #8b8383;
}
.main-wrapper .faq a {
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

/* footer  */
.main-footer {
  text-align: left;
  margin-top: 10px;
}
.main-footer > div > div:nth-child(2) {
  margin-top: 20px;
}
.main-footer .contact-info {
  color: #fff;
}
.main-footer .contact-info span {
  color: #fff;
  font-size: 14px;
}
.main-footer .contact-info a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}
.main-footer .contact-info .contact {
  color: #fb8a06;
}
.copy-right p {
  color: #8b8383;
  font-size: 12px;
}
/* input */
.form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}
.form-group input {
  font-size: 14px;
  border-radius: 38px;
  padding: 6px 18px;
  border: 1px solid gray;
}
.form-group input[type="text"]::placeholder {
  color: #bbb9b9 !important;
  font-size: 14px;
}
.form-group input:focus {
  box-shadow: unset;
}
.set-width {
  width: 35%;
}
.company {
  padding-right: 100px;
}
.faq {
  margin-top: 36px;
}
/* map  */
.map {
  width: 64%;
  position: relative;
  left: -110px;
}
.map .location-drop {
  position: absolute;
  top: 102px;
  left: 118px;
}
.map .location-drop .address-detail {
  position: absolute;
  height: 0;
  transition: height 0.5s;
}
.map .location-drop img {
  width: 26px;
  cursor: pointer;
}
.map .location-drop .address-detail {
  margin: 5px 0;
}
.map .location-drop:hover .address-detail {
  height: 50px;
}
.address-detail .line {
  width: 1px;
  background-color: gray;
  height: 100%;
  position: relative;
  left: 12px;
}
.address-detail .fade-in {
  opacity: 0;
  min-width: 120px;
  text-align: left;
}
.map .location-drop:hover .address-detail .fade-in {
  opacity: 1;
  transition-delay: 0.5s;
}
.map .location-drop:nth-child(2) .line {
  margin-left: -1px;
}
.map .location-drop:nth-child(3) {
  right: 41%;
  left: unset;
  top: 66%;
  display: none;
}
.map .location-drop:nth-child(3) > div {
  display: flex;
  align-items: flex-end;
  position: relative;
  top: -48px;
}
.map .location-drop:nth-child(4) {
  right: 194px;
  left: unset;
  top: 122px;
}
.social-icon a img {
  width: 20px;
  margin: 4px 5px 0 0;
}
.active {
  height: 50px !important;
}
.active .fade-in {
  opacity: 1;
}
.location-text {
  font-size: 12px;
  padding: 5px;
  color: #fff;
  position: relative;
  left: -22px;
  min-width: 200px;
}
@media (max-width: 479px) and (min-width: 320px) {
  .location-text {
    min-width: 100%;
  }
  .main-wrapper {
    flex-direction: column;
  }
  .map {
    width: 100%;
    left: 0 !important;
  }
  .map img {
    width: 100%;
  }
  .map .location-drop {
    top: 40px;
    left: 46px;
  }
  .map .location-drop:nth-child(4) {
    right: 73px;
    left: unset;
    top: 24px;
  }
  .map .location-drop:nth-child(3) {
    right: 41%;
    left: unset;
    top: 45%;
  }
  .set-width {
    width: 100%;
  }
  .company {
    padding-right: 40px;
  }
  .main-footer {
    flex-direction: column-reverse;
  }
  .form-group {
    margin: 25px 0;
  }
  .address-detail .fade-in {
    width: 100%;
  }
}
</style>
