import { createStore } from "vuex";

export default createStore({
  state: {
    dataTrans: {},
    translation: {},
    loading: false,
    productList: [],
  },
  getters: {},
  mutations: {
    setProductList: (state, payload) => {
      state.productList = payload;
    },
    setTranslationData: (state, payload) => {
      state.dataTrans = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setLang: (state, payload) => {
      state.translation = payload;
    },
  },
  actions: {
    setLanguage({ state, commit }, lang = "en") {
      let data = {};
      let object = state.dataTrans;
      for (const key in object) {
        let dataArray = object[key];
        let makeObj = {};
        let porfiles = [];
        dataArray.forEach((data) => {
          for (const key1 in data) {
            if (key === "Home") {
              makeObj[key1] = data[key1][lang];
            } else {
              porfiles.push(data[key1]);
            }
          }
        });
        data[key] = key === "Home" ? makeObj : porfiles;
      }
      const color = {
        1: "#13B3C1",
        2: "#ffe3b8",
        3: "#FFD363",
        4: "#fee2ff",
        5: "#deffd6",
      };
      const product = [];
      let index = 1;
      while (data.Home[`product_${index}_title`]) {
        product.push({
          slug: index,
          color: color[index] || "#fee2ff",
          title: data.Home[`product_${index}_title`],
          description: data.Home[`product_${index}_description`],
          image: data.Home[`product_${index}_image`],
          content_title: data.Home[`product_${index}_content_title`],
          content_image: data.Home[`product_${index}_content_image`],
          content_description:
            data.Home[`product_${index}_content_description`],
          feature: data.Home.product_feature,
          product_feature_description:
            data.Home[`product_${index}_feature_description`],
          product_feature_1: data.Home[`product_${index}_feature_1`],
          product_feature_1_des: data.Home[`product_${index}_feature_1_des`],
          product_feature_2: data.Home[`product_${index}_feature_2`],
          product_feature_2_des: data.Home[`product_${index}_feature_2_des`],
          product_feature_3: data.Home[`product_${index}_feature_3`],
          product_feature_3_des: data.Home[`product_${index}_feature_3_des`],
          product_feature_4: data.Home[`product_${index}_feature_4`],
          product_feature_4_des: data.Home[`product_${index}_feature_4_des`],
          product_connect_title: data.Home[`product_${index}_connect_title`],
          product_connect_des: data.Home[`product_${index}_connect_des`],
          product_connect_button: data.Home[`product_${index}_connect_button`],
        });
        index++;
      }
      commit("setLang", data);
      commit("setProductList", product);
    },
  },
  modules: {},
});
