<!-- eslint-disable prettier/prettier -->
<template>
  <section class="our-team" id="our-team">
    <div class="container">
      <div class="row">
        <hr class="team-hr" />
        <h6 class="heading">{{ $store.state.translation.Home.our_team }}</h6>
        <!-- <p>{{ $store.state.translation.Profiles }}</p> -->
        <div class="team-wrapper">
          <!-- expert section 1  -->
          <div class="expert" v-for="(p, key) in profileData" :key="key">
            <div class="expert-left">
              <div class="team-card" v-if="p.cardA && p.cardA[0]">
                <div class="left-content">
                  <h3>{{ p.cardA[0].key }}</h3>
                  <div class="divider-line">
                    <img src="../../assets/img/circle.png" alt="circle" />
                    <img
                      src="../../assets/img/Line.png"
                      alt="line"
                      class="line-img"
                    />
                  </div>
                  <p>{{ p.cardA[0].Role }}</p>
                  <div class="team-icons">
                    <a :href="p.cardA[0].LinkedIn_link" target="_blank">
                      <img
                        src="../../assets/img/linkedin-blue.png"
                        alt="linkedin"
                    /></a>
                    <!-- <router-link to="/"
                        ><img src="../../assets/img/x-blue.png" alt="x"
                      /></router-link>
                      <router-link to="/"
                        ><img
                          src="../../assets/img/insta-blue.png"
                          alt="instagram"
                      /></router-link>
                      <router-link to="/"
                        ><img src="../../assets/img/fb-blue.png" alt="fb"
                      /></router-link> -->
                  </div>
                </div>
                <div class="right-content blue-color">
                  <img
                    :src="require(`@/assets/img/${p.cardA[0].Image_link}`)"
                    alt="Male"
                  />
                </div>
              </div>

              <div class="team-card" v-if="p.cardB && p.cardB[0]">
                <div class="left-content">
                  <h3>{{ p.cardB[0].key }}</h3>
                  <div class="divider-line">
                    <img src="../../assets/img/circle2.png" alt="circle" />
                    <img
                      src="../../assets/img/Line2.png"
                      alt="line"
                      class="line-img"
                    />
                  </div>
                  <p>{{ p.cardB[0].Role }}</p>
                  <div class="team-icons">
                    <a :href="p.cardB[0].LinkedIn_link" target="_blank"
                      ><img
                        src="../../assets/img/linkedin-blue.png"
                        alt="linkedin"
                    /></a>
                    <!-- <router-link to="/"
                        ><img src="../../assets/img/x-blue.png" alt="x"
                      /></router-link>
                      <router-link to="/"
                        ><img
                          src="../../assets/img/insta-blue.png"
                          alt="instagram"
                      /></router-link>
                      <router-link to="/"
                        ><img src="../../assets/img/fb-blue.png" alt="fb"
                      /></router-link> -->
                  </div>
                </div>
                <div class="right-content green-color">
                  <img
                    :src="require(`@/assets/img/${p.cardB[0].Image_link}`)"
                    alt="Male"
                  />
                </div>
              </div>
            </div>
            <div class="expert-text">
              <p>{{ $store.state.translation.Home.meet_out_expert }}</p>
            </div>
            <div class="expert-right">
              <div class="team-card" v-if="p.cardA && p.cardA[1]">
                <div class="right-content green-color">
                  <img
                    :src="require(`@/assets/img/${p.cardA[1].Image_link}`)"
                    alt="Male"
                  />
                </div>
                <div class="right-side-content">
                  <h3>{{ p.cardA[1].key }}</h3>
                  <div class="divider-line">
                    <img
                      src="../../assets/img/Line2.png"
                      alt="line"
                      class="line-img"
                    />
                    <img src="../../assets/img/circle2.png" alt="circle" />
                  </div>
                  <p>{{ p.cardA[1].Role }}</p>
                  <div class="team-icons">
                    <a :href="p.cardA[1].LinkedIn_link" target="_blank"
                      ><img
                        src="../../assets/img/linkedin-blue.png"
                        alt="linkedin"
                    /></a>
                    <!-- <router-link to="/"
                        ><img src="../../assets/img/x-blue.png" alt="x"
                      /></router-link>
                      <router-link to="/"
                        ><img
                          src="../../assets/img/insta-blue.png"
                          alt="instagram"
                      /></router-link>
                      <router-link to="/"
                        ><img src="../../assets/img/fb-blue.png" alt="fb"
                      /></router-link> -->
                  </div>
                </div>
              </div>

              <div class="team-card" v-if="p.cardB && p.cardB[1]">
                <div class="right-content blue-color">
                  <img
                    :src="require(`@/assets/img/${p.cardB[1].Image_link}`)"
                    alt="Male"
                  />
                </div>
                <div class="right-side-content">
                  <h3>{{ p.cardB[1].key }}</h3>
                  <div class="divider-line">
                    <img
                      src="../../assets/img/Line.png"
                      alt="line"
                      class="line-img"
                    />
                    <img src="../../assets/img/circle.png" alt="circle" />
                  </div>
                  <p>{{ p.cardB[1].Role }}</p>
                  <div class="team-icons">
                    <a :href="p.cardB[1].LinkedIn_link" target="_blank"
                      ><img
                        src="../../assets/img/linkedin-blue.png"
                        alt="linkedin"
                    /></a>
                    <!-- <router-link to="/"
                        ><img src="../../assets/img/x-blue.png" alt="x"
                      /></router-link>
                      <router-link to="/"
                        ><img
                          src="../../assets/img/insta-blue.png"
                          alt="instagram"
                      /></router-link>
                      <router-link to="/"
                        ><img src="../../assets/img/fb-blue.png" alt="fb"
                      /></router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="team-bottom">
            <h3>
              {{ $store.state.translation.Home.our_team_description }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  data() {
    return {
      profileData: [],
    };
  },
  mounted() {
    const { Profiles } = this.$store.state.translation;
    if (Profiles?.length) {
      const profileData = [];
      for (let i = 0; i < Profiles.length; i += 4) {
        let l = Profiles.slice(i, i + 4);
        let obj = {};
        for (let index = 0; index < l.length; index += 2) {
          obj[`card${!index ? "A" : "B"}`] = l.slice(index, index + 2);
        }
        profileData.push(obj);
      }
      this.profileData = profileData;
    }
  },
};
</script>
<!-- eslint-disable prettier/prettier -->
<style>
.our-team {
  padding: 0px 0 40px 0;
}

.expert .expert-text {
  width: 250px;
  height: 250px;
  background: #f0eeee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expert .expert-text p {
  font-size: 18px;
  color: #000000;
  margin: 0;
  font-weight: 700;
}
.team-wrapper .expert {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
/* card  */
.team-card {
  display: flex;
}

.team-card .left-content h3 {
  margin: 0;
  font-size: 14px;
  color: #000000;
  padding-left: 35px;
  font-weight: 600;
}
.team-card .left-content p {
  margin: 0;
  font-size: 12px;
  color: #fdb806;
  padding-left: 35px;
}
.team-card .left-content .divider-line {
  display: flex;
  align-items: center;
  margin-right: -3px;
}
.team-card .left-content .team-icons {
  display: flex;
  align-items: center;
  padding-left: 35px;
}
.team-card .left-content .team-icons img {
  width: 22px;
  margin-right: 10px;
  margin-top: 8px;
}
.team-card .right-side-content h3 {
  margin: 0;
  font-size: 14px;
  color: #000000;
  padding-left: 25px;
  font-weight: 600;
}
.team-card .right-side-content p {
  margin: 0;
  font-size: 12px;
  color: #fdb806;
  padding-left: 25px;
}
.team-card .right-side-content .divider-line {
  display: flex;
  align-items: center;
  margin-left: -3px;
}
.team-card .right-side-content .team-icons {
  display: flex;
  align-items: center;
  padding-left: 25px;
}
.team-card .right-side-content .team-icons img {
  width: 22px;
  margin-right: 10px;
  margin-top: 8px;
}
.right-content {
  border-radius: 50%;

  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue-color {
  background: #c8e3e9;
}
.green-color {
  background: #c6ead8;
}
.right-content img {
  width: 50px;
}
.line-img {
  width: 250px;
}
.expert-right {
  position: relative;
  left: -50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
  width: 332px;
}
.expert-left {
  position: relative;
  right: -50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
  width: 332px;
}
.team-bottom {
  display: flex;
  justify-content: center;
}
.team-bottom h3 {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  width: 50%;
  line-height: 22px;
}
.team-hr {
  color: #f1a208;
  border-top: 2px solid #a813b5;
  opacity: unset;
  padding-bottom: 50px;
  width: 99%;
  margin: 0 auto;
}
@media (max-width: 479px) and (min-width: 320px) {
  .our-team {
    padding: 40px 15px;
  }
  .line-img {
    width: 100%;
  }
  .team-wrapper .expert {
    flex-wrap: wrap;
  }
  .expert-right {
    left: -6px;
    width: 100%;
  }
  .expert-left {
    right: 0;
    width: 100%;
  }
  .team-bottom h3 {
    width: 100%;
  }
  .right-side-content {
    width: 80%;
  }
  .left-content {
    width: 80%;
  }
  .team-card {
    height: 130px;
    display: flex;
    align-items: center;
  }
}
</style>
