<!-- eslint-disable prettier/prettier -->
<template>
  <!-- contact section  -->
  <section class="contact-us" id="contact">
    <div class="container">
      <div class="row">
        <hr class="about-hr" />
      </div>
    </div>
    <div class="contact-inner">
      <div class="container">
        <div class="row">
          <h6 class="heading">
            {{ $store.state.translation.Home.contact_us }}
          </h6>
          <div class="col-md-6">
            <div class="future-content">
              <h6>{{ $store.state.translation.Home.contact_title }}</h6>
              <p>
                {{ $store.state.translation.Home.contact_des }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <form class="contact-form" :action="'mailto:' + $store.state.translation.Home.wizz_redirect_mail" method="post" enctype="text/plain">
              <input type="text" name="name" v-model="name" :placeholder="this.$store.state.translation.Home.contact_placeholder_name" required />
              <input
                type="email"
                name="email"
                v-model="email"
                :placeholder="this.$store.state.translation.Home.contact_placeholder_email"
                required
              />
              <input
                type="text"
                name="Subject"
                v-model="subject"
                :placeholder="this.$store.state.translation.Home.contact_placeholder_subject"
                required
              />
              <textarea v-model="message" rows="4" cols="50" name="Message" :placeholder="this.$store.state.translation.Home.contact_placeholder_msg"></textarea>
              <p>
                By submitting you agree to our <a href="#"> Term of Use</a> &
                <a href="#">Privacy Policy</a>
              </p>
              <input type="submit" value="Submit" class="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- contact section End -->
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  name: "ContactUs",
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  },
  methods: {
    submitForm() {
      const mailtoLink = `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.message)}`;
      
      window.location.href = mailtoLink;
    }
  }
};
</script>
<!-- eslint-disable prettier/prettier -->
<style scoped>
.about-hr {
  color: #f1a208;
  border-top: 2px solid #f1a208;
  opacity: unset;
  padding-bottom: 50px;
  width: 99%;
  margin: 0 auto;
}
.contact-us {
  padding: 40px 0;
}
.contact-us .heading {
  padding-bottom: 30px;
}
.contact-inner {
  background-color: #f4fbfc;
  padding: 40px 0;
}
.contact-us .future-content {
  padding: 15px 0px !important;
  width: 80%;
}

.contact-form input,
.contact-form textarea {
  padding: 12px 10px;
  font-size: 14px;
  margin: 12px 0;
  background-color: #fff !important;
  border: 1px solid #d3d3d3;
  color: #999999;
  width: 100%;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"] {
  color: #999999 !important;
}

.contact-form p {
  color: #303043;
  font-size: 14px;
  margin-top: 5px;
}

.contact-form p a {
  color: #2146e2 !important;
}

.contact-form .submit {
  background: #50036b !important;
  color: #fff !important;
  font-size: 16px;
  border-radius: 50px;
  width: 30%;
  margin-top: 30px;
}
.contact-form .submit:hover {
  background: #000 !important;
}
.contact-us .section-title p {
  font-size: 14px !important;
}

.contact-container {
  background-color: #f4fbfc;
}

.contact-row {
  margin-top: 40px;
}

.contact-us .future-content h6 {
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
}

.contact-us .future-content p {
  line-height: 1.8;
  margin-bottom: 0;
  font-size: 16px;
}

.contact-page {
  width: 85%;
}

.contact-page input:first-child {
  margin-top: 0 !important;
}

.call-us {
  padding: 15px;
  background-color: #fff;
  margin-bottom: 25px;
  border-radius: 4px;
}

.call-us p {
  color: #111111;
  font-size: 16px;
  margin: 0;
  padding-bottom: 6px;
}

.call-us h6 {
  color: #111111;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
</style>
