<!-- eslint-disable prettier/prettier -->
<template>
  <ul class="dropdown-menu">
    <!-- <li class="menu-heading">{{ $store.state.translation.Home.service }}</li> -->
    <li v-if="$store.state.translation.Home.service_dropdown1"><a :href="$store.state.translation.Home.service_dropdown_link1">{{ $store.state.translation.Home.service_dropdown1 }}</a></li>
    <li v-if="$store.state.translation.Home.service_dropdown2"><a :href="$store.state.translation.Home.service_dropdown_link2">{{ $store.state.translation.Home.service_dropdown2 }}</a></li>
    <li v-if="$store.state.translation.Home.service_dropdown3"><a :href="$store.state.translation.Home.service_dropdown_link3">{{ $store.state.translation.Home.service_dropdown3 }}</a></li>
    <!-- <li v-if="$store.state.translation.Home.service_dropdown4"><router-link :to="/">{{ $store.state.translation.Home.service_dropdown4 }}</router-link></li>
    <li v-if="$store.state.translation.Home.service_dropdown5"><router-link :to="/">{{ $store.state.translation.Home.service_dropdown5 }}</router-link></li> -->
  </ul>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  
};
</script>
<!-- eslint-disable prettier/prettier -->
<style scoped>
.dropdown-menu {
  padding: 15px;
  list-style: none;
  border-radius: unset;
  /* display: block !important; */
  min-width: 200px;
  border-top: 2px solid #75047e;
  transition: all 1s ease-in-out;
}
.navigation-list > ul > li:hover ul.dropdown-menu {
  display: block;
}
.dropdown-menu li {
  padding-bottom: 6px !important;
}
.menu-heading {
  font-weight: bold;
  font-size: 16px !important;
}
.dropdown-menu li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
  padding: 10px 0;
  font-weight: 500;
}
.dropdown-menu li a:hover {
  color: #75047e;
}
</style>
