<!-- eslint-disable prettier/prettier -->
<template>
  <section class="product-detail">
    <div class="container">
      <div class="row">
        <h6>{{ productDetail.title }}</h6>
        <p>{{ productDetail.description }}</p>
        <div class="product-content-container">
          <div
            class="product-content"
            :style="{ border: ` 5px solid ${productDetail.color}` }"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="detail-card">
                  <h3>
                    {{ productDetail.content_title }}
                  </h3>
                  <!-- <p v-if="showContent">
                    {{ truncatedContent }}
                  </p> -->
                  <p >
                    {{ content }}
                  </p>
                  <!-- <span v-if="isTruncated " @click="showFullContent">{{ $store.state.translation.Home.read_more }}</span>
                  <span v-if="showContent" @click="showFullContent">Less</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="product-right-side">
                  <div class="product-image">
                    <img
                      :src="productDetail.content_image"
                      alt="gif"
                      v-if="productDetail.content_image"
                    />
                    <img src="../../assets/img/GIF.png" alt="gif" v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
export default {
  data() {
    return {
      productDetail: {},
      content:'',
      maxLength: 100,
      showContent: false,
    };
  },
  computed: {
    truncatedContent: function () {
      return this.content.substring(0, this.maxLength);
    },
    fullContent: function () {
      return this.content;
    },
    isTruncated: function () {
      return this.content.length > this.maxLength;
    },
  },
  methods: {
    showFullContent() {
      this.showContent = !this.showContent;
    },
    filterProduct() {
      const { id } = this.$route.params;
      const filter = this.$store.state.productList.find((i) => i.slug == id);
      this.productDetail = filter;
      this.content = filter.content_description
    },
  },
  mounted() {
    this.filterProduct();
  },
};
</script>
<!-- eslint-disable prettier/prettier -->
<style>
.product-detail {
  padding: 40px 0;
}
.product-detail h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  color: #000000;
}
.product-detail p {
  font-size: 14px;
  margin: 0;
  text-align: center;
  color: #413f3f;
  padding: 20px 0;
  font-weight: 500;
}
.product-content-container {
  padding: 20px 50px !important;
  display: flex;
  justify-content: center;
}
.product-content {
  /* border: 5px solid #f8caff; */
  padding: 50px !important;
  border-radius: 20px;
  width: 90%;
}
.detail-card {
  width: 90%;
}
.detail-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #000000;
  line-height: 1.5;
}
.detail-card p {
  font-size: 14px;
  margin: 0;
  text-align: left !important;
  color: #b2abab;
  padding: 20px 0;
  font-weight: 500;
  line-height: 1.5;
}
.detail-card span {
  font-size: 14px;
  color: #297fff;
  padding: 20px 0;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}
.product-right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.product-image img {
  width: 100%;
}
@media (max-width: 479px) and (min-width: 320px) {
  .product-content-container {
    padding: 20px !important;
  }
  .product-content {
    padding: 30px !important;
    width: 100%;
  }
  .product-right-side {
    margin-top: 30px;
  }
}
</style>
