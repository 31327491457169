<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <ProductSlider />
    <ProductDetail />
    <ProductFeature />
    <!-- <ConectCommunity />  -->
  </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import ProductDetail from "../components/product/productDetail.vue";
import ProductFeature from "../components/product/productFeature.vue";
import ProductSlider from "../components/product/productSlider.vue";
// import ConectCommunity from "../components/product/connectCommunity.vue";
export default {
  components: {
    ProductSlider,
    ProductDetail,
    ProductFeature,
    // ConectCommunity,
  },
};
</script>
<!-- eslint-disable prettier/prettier -->
<style></style>
