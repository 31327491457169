<template>
  <section class="hero-container">
    <div class="hero container">
      <div class="hero-left">
        <h2>
          {{ $store.state.translation.Home.hero_title }} <br />
          <!-- <span class="success">{{
            $store.state.translation.Home.hero_success
          }}</span> -->
          <span class="success" id="typed">{{ typedText }}</span
          ><span class="cursor">&nbsp;</span>
        </h2>
        <p class="para">
          {{ $store.state.translation.Home.hero_description }}
        </p>
        <div class="all-btn">
          <!-- :href="'mailto:' + $store.state.translation.Home.wizz_redirect_mail" -->
          <a href="#product" class="btn btn-outline btn-outline-white">
            {{ $store.state.translation.Home.free_quote }}
          </a>
          <a href="#contact" class="btn btn-white">
            {{ $store.state.translation.Home.contact_us }}
          </a>
        </div>
      </div>
      <div class="hero-right">
        <!-- data-aos="fade-left" data-aos-duration="1500" -->
        <div class="hero-image">
          <img src="../../assets/img/illustration.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
  data() {
    return {
      //
      toType: [...this.$store.state.translation.Home.hero_success?.split(",")],
      typedText: "",
      delayTypingChar: 100,
      delayErasingText: 100,
      delayTypingText: 2000,
      toTypeIndex: 0,
      charIndex: 0,
    };
  },
  mounted() {
    this.typeText();
  },
  methods: {
    typeText() {
      if (this.charIndex < this.toType[this.toTypeIndex].length) {
        this.typedText += this.toType[this.toTypeIndex].charAt(this.charIndex);
        this.charIndex++;
        setTimeout(this.typeText, this.delayTypingChar);
      } else {
        setTimeout(this.eraseText, this.delayTypingText);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        this.typedText = this.toType[this.toTypeIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex = this.charIndex - 1;
        setTimeout(this.eraseText, this.delayErasingText);
      } else {
        this.toTypeIndex++;
        if (this.toTypeIndex >= this.toType.length) this.toTypeIndex = 0;
        setTimeout(this.typeText, 0);
      }
    },
  },
};
</script>

<style scoped>
.cursor {
  display: inline-block;
  background-color: #b0ff95;
  animation: blinker 800ms infinite;
}

.cursor.typing-true {
  animation: none;
}

@keyframes blinker {
  0% {
    background-color: #dddddd;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #dddddd;
  }
}
.all-btn .btn {
  margin-right: 50px;
  padding: 6px 30px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
}
.btn-white {
  background: #fff;
  color: #50036b;
  border: 2px solid #fff;
  border-radius: 20px;
}
.btn-white:hover {
  border: 2px solid #50036b;
  background: #000;
  color: #fff;
}
.btn-outline-white {
  border: 2px solid #fff;
  color: #fff;
  border-radius: 20px;
}
.btn-outline-white:hover {
  border: 2px solid #fff;
  background: #fff;
  color: #50036b;
}
.hero-container {
  /* background-color: #0066cc; */
  background-image: url("../../assets/img/bgimage.png");
  background-size: cover;
  background-position: center;
  /* padding: 30px; */
  display: flex;
  justify-content: center;
  position: relative;
  height: 500px;
  overflow: hidden;
}
.hero-container:before {
  content: "";
  background: rgb(0 0 0 / 75%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero-container .hero {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-container .hero .hero-left {
  width: 50%;
  padding-bottom: 0px;
}

.hero-container .hero .hero-left h2 {
  font-size: 30px;
  color: #fff;
  line-height: 1.3;
  font-weight: 500;
  text-align: left;
  margin: 0;
}
.hero-container .hero .hero-left h2 span {
  color: #f6b34d;
}
.hero-container .hero .hero-left p.para {
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 90px;
  line-height: 24.2px;
  font-weight: 400;
  padding-right: 20px;
  font-style: normal;
  width: 80%;
}
.hero-container .hero .hero-left .all-btn .free-quote {
  font-size: 20px;
  color: #fff;
  border: 2px solid #fff;
  background: transparent;
  padding: 6px 25px;
  border-radius: 16px;
  text-decoration: none;
  margin-right: 50px;
}
.hero-container .hero .hero-left .all-btn .contact {
  font-size: 20px;
  color: #50036b;
  background: #fff;
  padding: 6px 25px;
  border-radius: 16px;
  text-decoration: none;
  /* border: 2px solid #fff; */
}

.hero-container .hero .hero-right {
  width: 50%;
}
.hero-image img {
  width: 100%;
  animation: shimmy 3s infinite;
  animation-direction: alternate;
}
@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 10px);
  }
}
.hero-container .hero .hero-right .hero-video {
  margin-left: 6rem;
}

@media (max-width: 479px) and (min-width: 320px) {
  .hero-container {
    padding: 30px 15px;
    height: 100% !important;
  }
  .hero-container .hero {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .hero-container .hero .hero-left {
    margin-top: 50px;
  }
  .hero-container .hero .hero-left p.para {
    width: 100%;
  }
  .all-btn .btn {
    margin-right: 10px;
  }
  .hero-container .hero .hero-left .all-btn .free-quote {
    margin-right: 20px !important;
  }
  .hero-container .hero .hero-left .all-btn a {
    padding: 6px 10px !important;
  }
  .hero-container .hero .hero-left {
    padding: 0px;
    width: 100%;
  }
  .hero-container .hero .hero-left h2 {
    font-size: 36px;
  }
  .discount-btn {
    margin-left: 0px;
  }
  .hero-container .hero .hero-right {
    width: 100%;
    margin-top: 50px;
  }
  .discount-container {
    flex-wrap: wrap;
    text-align: center;
  }
  .arich-card {
    flex-wrap: wrap;
  }
  .arich-card .card-dev {
    width: 100%;
  }
  .hero-container .hero .hero-left .hero-text span {
    margin-top: 10px;
  }
}

@media (max-width: 684px) and (min-width: 575px) {
}

@media (max-width: 767px) and (min-width: 685px) {
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 574px) and (min-width: 480px) {
}

@media (max-width: 1199px) and (min-width: 1024px) {
}
</style>
