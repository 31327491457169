<template>
  <div class="home">
    <FrontPage />
  </div>
</template>

<script>
import FrontPage from "@/components/main/FrontPage.vue";

export default {
  name: "IndexPage",
  components: {
    FrontPage,
  },
};
</script>
