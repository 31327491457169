<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <!-- section header end -->
    <section class="header-section">
      <div class="header-primary">
        <div class="header container">
          <div class="primary-list active-class">
            <div class="logo active-logo">
              <router-link to="/"
                ><img src="../../assets/img/logo.png"
              /><div class="logo-texts"><span>Wizz<span class="tech">Tech</span></span></div></router-link>
              <!-- <div class="logo-text"><span>WizzTech</span></div> -->
            </div>
            <!-- <div>
              <form class="nigisi-search">
                <input
                  type="text"
                  :placeholder="$store.state.translation.Home.search"
                  name="search2"
                />
                <button type="submit">
                  <img src="../../assets/img/Search-icon.png" />
                </button>
              </form>
            </div> -->
            <div class="navigation-list">
              <ul class="nav-list">
                <li>
                  <router-link to="/">
                    {{ $store.state.translation.Home.home }}</router-link
                  >
                </li>

                <li>
                  <a href="#about">
                    {{ $store.state.translation.Home.about }}</a
                  >
                </li>
                <li>
                  <a
                    href="#product"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="link-innner">
                      {{ $store.state.translation.Home.projects }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        class="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </div>
                  </a>
                  <ServiceDropdown />
                </li>
                <li>
                  <a href="#service"
                    >{{$store.state.translation.Home.service }}
                  </a>
                </li>
                <li>
                  <a href="#our-team"
                    >{{ $store.state.translation.Home.our_team }}
                  </a>
                </li>
                <!-- <li>
                  <router-link to="/contact">{{
                    $store.state.translation.Home.contact
                  }}</router-link>
                </li> -->
                <li>
                  <a :href="'mailto:' + $store.state.translation.Home.wizz_redirect_mail"
                   class="btn btn-purple">
                    {{ $store.state.translation.Home.quick_enquiry }}
                  </a>
                </li>
                <li>
                  <div class="dropdown-center">
                    <div class="lang-menu" v-on:click="toggleMenu2">
                      <img src="../../assets/img/globe.png" alt="Lang" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        class="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </div>
                    <ul class="dropdown-menu1" v-if="dropMenu">
                      <li
                        @click="handleLang('en')"
                        :class="{ 'active-lang': activeLang === 'en' }"
                      >
                        <a class="dropdown-item" href="#">English</a>
                      </li>
                      <li
                        @click="handleLang('ja')"
                        :class="{ 'active-lang': activeLang === 'ja' }"
                      >
                        <a class="dropdown-item" href="#">Japanese</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section header end -->
    <!-- Mobile header start  -->
    <div class="mob-header">
      <div class="nav-list">
        <div class="logo">
          <router-link to="/">
            <img src="../../assets/img/logo.png" alt="img" />
            <div class="logo-texts"><span>Wizz<span class="tech">Tech</span></span></div>
          </router-link>
        </div>
        <div class="mobile-header-menu">
          <button class="memu-icon" v-on:click="toggleMenu">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 fill-current"
              data-v-705463ae=""
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 6a1 1 0
              011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 18a1 1 0 011-1h18a1 1 0
              110 2H3a1 1 0 01-1-1z"
                data-v-705463ae=""
              ></path>
            </svg>
          </button>
          <div class="dropdown-center">
            <div class="lang-menu" v-on:click="toggleMenu2">
              <img src="../../assets/img/globe.png" alt="Lang" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            </div>
            <ul class="dropdown-menu1" v-if="dropMenu">
              <li
                @click="handleLang('en')"
                :class="{ 'active-lang': activeLang === 'en' }"
              >
                <a class="dropdown-item" href="#">English</a>
              </li>
              <li
                @click="handleLang('ja')"
                :class="{ 'active-lang': activeLang === 'ja' }"
              >
                <a class="dropdown-item" href="#">Japanese</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mob-menu">
        <div class="dropdown-menu2" v-if="mobileMenu">
          <div class="close-btn" v-on:click="toggleMenu">
            <svg
              data-v-705463ae=""
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 fill-current"
            >
              <path
                data-v-705463ae=""
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.707 5.293a1 1 0 010 1.414l-12 12a1 1 0
                01-1.414-1.414l12-12a1 1 0 011.414 0z"
              ></path>
              <path
                data-v-705463ae=""
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.293 5.293a1 1 0 011.414 0l12 12a1 1 0 01-1.414
                1.414l-12-12a1 1 0 010-1.414z"
              ></path>
            </svg>
          </div>
          <ul class="menu" v-on:click="toggleMenu">
            <li>
              <router-link to="/">
                <svg
                  data-v-705463ae=""
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-5 h-5 mr-2"
                >
                  <path
                    data-v-705463ae=""
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2
                  2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0
                  011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
                {{ $store.state.translation.Home.home }}</router-link
              >
            </li>
            <li>
              <router-link to="/about">
                <svg
                  data-v-705463ae=""
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-5 h-5 mr-2"
                >
                  <path
                    data-v-705463ae=""
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0
                  01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1
                  1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1
                  1h-2a1 1 0 01-1-1v-6z"
                  ></path>
                </svg>
                {{ $store.state.translation.Home.about }}
              </router-link>
            </li>
            <li>
              <router-link to="/service">
                <svg
                  data-v-705463ae=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="w-5 mr-2 feather feather-code"
                >
                  <polyline
                    data-v-705463ae=""
                    points="16 18 22 12 16 6"
                  ></polyline>
                  <polyline
                    data-v-705463ae=""
                    points="8 6 2 12 8 18"
                  ></polyline>
                </svg>
                {{ $store.state.translation.Home.service }}
              </router-link>
            </li>
            <li>
              <router-link to="/projects">
                <svg
                  data-v-705463ae=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="w-5 mr-2 feather feather-upload-cloud"
                >
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                  <line
                    data-v-705463ae=""
                    x1="12"
                    y1="12"
                    x2="12"
                    y2="21"
                  ></line>
                  <path
                    data-v-705463ae=""
                    d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
                  ></path>
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                </svg>
                {{ $store.state.translation.Home.projects }}</router-link
              >
            </li>
            <li>
              <router-link to="/our-team">
                <svg
                  data-v-705463ae=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="w-5 mr-2 feather feather-upload-cloud"
                >
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                  <line
                    data-v-705463ae=""
                    x1="12"
                    y1="12"
                    x2="12"
                    y2="21"
                  ></line>
                  <path
                    data-v-705463ae=""
                    d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
                  ></path>
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                </svg>
                {{ $store.state.translation.Home.our_team }}</router-link
              >
            </li>

            <li>
              <router-link to="/contact">
                <svg
                  data-v-705463ae=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="w-5 mr-2 feather feather-upload-cloud"
                >
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                  <line
                    data-v-705463ae=""
                    x1="12"
                    y1="12"
                    x2="12"
                    y2="21"
                  ></line>
                  <path
                    data-v-705463ae=""
                    d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
                  ></path>
                  <polyline
                    data-v-705463ae=""
                    points="16 16 12 12 8 16"
                  ></polyline>
                </svg>
                {{ $store.state.translation.Home.contact }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceDropdown from "./serviceDropdown.vue";
export default {
  name: "AppHeader",
  components: {
    ServiceDropdown,
  },
  data() {
    return {
      mobileMenu: false,
      dropMenu: false,
      activeLang: "en",
    };
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    this.activeLang = lang;
  },
  methods: {
    handleLang(lang) {
      // window?.resetLanguage("en");
      localStorage.setItem("lang", lang);
      this.$store.dispatch("setLanguage", lang);
      this.dropMenu = false;
    },
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    toggleMenu2() {
      this.dropMenu = !this.dropMenu;
    },
  },
};
</script>

<style scoped>
/* header start  */
.logo .router-link-exact-active {
  background-image: unset !important;
}
.dropdown-toggle {
  transition: transform 1s ease;
}
.header-section {
  background-color: #fff;
  position: relative;
  z-index: 1;
  /* position: fixed; */
  /* top: 0; */
  border: 1px solid #eee;
}
.since-primary {
  background-color: #000000;
  text-align: center;
  padding: 10px;
}
.link-innner svg {
  margin-left: 5px;
}
.btn-purple {
  background: #50036b;
  color: #fff !important;
  border: 2px solid #50036b;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 30px !important;
  text-decoration: none !important;
}
.btn-purple:hover {
  background: #000;
  color: #fff !important;
  text-decoration: none !important;
}
.btn-purple:after {
  background-image: unset !important;
  color: #fff !important;
}
.dropdown-toggle::after {
  border: unset !important;
}
.since-primary h6 {
  margin: 0;
  font-size: 13px;
  color: #fff;
  word-spacing: 1.5px;
  letter-spacing: 1.5px;
}
.header-primary {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  padding: 0;
  background: #fff;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 0 50px; */
}
.header .primary-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header .active-logo .router-link-exact-active {
  background-size: 100% 5px !important;
  border-radius: unset !important;
}
.header .logo a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 6px 0;
}
.header .logo img {
  height: 55px;
}
.header .logo a .logo-texts {
  font-size: 24px;
  font-weight: 500;
  padding-left: 10px;
  color: #75047e;
}
.header .logo a .logo-texts .tech {
  /* font-size: 14px !important; */
  color: #f6b34d !important;
}
.header .active-class .router-link-exact-active {
  background-image: linear-gradient(90deg, #620483, #620483);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0 68.5%;
  transition: background-size 0.25s ease-in;
  text-decoration: none;
  /* border-radius: 4px; */
  color: #75047e !important;
}
.header .active-class .router-link-exact-active img {
  display: flex;
}
.navigation-list > ul > li > a:hover img {
  display: flex;
}
.navigation-list > ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}
.navigation-list > ul li {
  list-style-type: none;
  border-radius: 8px;
  top: 2px;
  padding: 0px 12px;
}
.navigation-list .nav-list li:last-child {
  padding-right: 0;
}
.navigation-list .nav-list li:first-child {
  padding-left: 0;
}
.navigation-list .nav-list > li > a {
  text-decoration: none;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 5px; */
  padding: 32px 0;
  white-space: nowrap;
}
.navigation-list > ul > li > a:hover {
  color: #75047e;
}
.navigation-list > ul > li:hover ul.dropdown-menu2 {
  display: block;
}
.navigation-list > ul > li > a img {
  width: 14px;
  display: none;
  position: absolute;
  top: -10px;
}
.navigation-list > ul > li > a:after {
  content: "";
  width: 0;
  height: 2px;
  display: block;
  position: relative;
  top: 5px;
  background-image: linear-gradient(90deg, #620483, #620483);
  background-repeat: no-repeat;
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}
.navigation-list > ul > li > a:hover:after {
  width: 100%;
}
.lets-talk {
  display: flex;
  align-items: center;
  border-bottom: unset !important;
}
.lets-talk .btn-talk {
  background-color: #620483;
  border-radius: 14px;
  font-weight: 400;
  padding: 6px 40px !important;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  border: unset;
  /* box-shadow: 1px 1px 3px 0 rgba(209, 8, 249, 0.158); */
}
.lets-talk .price-list ul {
  border-left: unset;
}
.lets-talk .btn-talk:hover {
  transition-duration: 0.5s;
  background-color: #000;
}
.dropdown-head {
  display: block;
}
.hover-dropdown:hover .dropdown-head {
  display: block;
}
/* header end */
.mob-header {
  display: none;
}
form.nigisi-search {
  display: flex;
  margin: auto;
  max-width: 500px;
  min-width: 150px;
  border-bottom: 1px solid #bbb9b9;
}
form.nigisi-search input[type="text"] {
  font-size: 14px;
  border: unset;
  float: left;
  width: 100%;
  background: #fff;
  color: #bbb9b9 !important;
}
form.nigisi-search input[type="text"]::placeholder {
  color: #bbb9b9 !important;
}
form.nigisi-search input:focus,
form.nigisi-search input:active {
  outline: none;
}
form.nigisi-search button {
  float: left;
  width: 20%;
  padding: 5px 10px;
  background: transparent;
  color: white;
  border: unset;
  border-left: none;
  cursor: pointer;
}

form.nigisi-search button img {
  width: 16px;
}

form.nigisi-search::after {
  content: "";
  clear: both;
  display: table;
}
.dropdown-menu1 {
  position: absolute;
  background: #fff;
  padding: 10px 15px;
  border: 1px solid #eee;
  left: -25px;
  top: 26px;
}
.dropdown-menu1 li {
  padding-left: 0 !important;
  padding-bottom: 4px !important;
}
.dropdown-menu1 li .dropdown-item {
  font-size: 14px;
  color: #000;
}
.dropdown-menu1 li .dropdown-item:hover {
  color: #620483;
}
.lang-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.lang-menu img {
  width: 20px;
  margin-right: 5px;
}
@media (max-width: 479px) and (min-width: 320px) {
  .hero-container {
    padding: 30px;
  }
  .hero-container .hero {
    flex-wrap: wrap;
  }
  .hero-container .hero .hero-left {
    padding: 0px;
    width: 100%;
  }
  .hero-container .hero .hero-left h2 {
    font-size: 30px;
  }
  .discount-btn {
    margin-left: 0px;
  }
  .hero-container .hero .hero-right {
    display: none;
  }
  .discount-container {
    flex-wrap: wrap;
    text-align: center;
  }
  .arich-card {
    flex-wrap: wrap;
  }
  .arich-card .card-dev {
    width: 100%;
  }
  .since-primary h6 {
    font-size: 10px;
  }

  /* Mobile menu Responsive css */
  .header-primary {
    display: none;
  }
  .close-btn {
    float: right;
  }
  .close-btn svg {
    width: 30px;
    height: 30px;
    color: #000000;
    fill: currentColor;
    background-color: #f7fafc;
    border: 1px solid #f7fafc;
  }
  .mobile-header-menu {
    display: flex;
    align-items: center;
  }
  .mobile-header-menu .dropdown-menu1 {
    z-index: 9;
    list-style: none;
  }
  .mob-header {
    display: block;
    /* position: fixed; */
    z-index: 1;
    top: 0px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .dropdown-menu2 {
    width: 100%;
    list-style: none;
    background-color: #fff;
    height: 100vh;
    padding: 25px 40px 20px 20px;
  }
  .nav-list {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }
  .memu-icon {
    border-radius: 0.25rem;
    background-color: #f7fafc;
    cursor: pointer;
    border: none;
    padding: 6px 8px;
  }
  .memu-icon svg {
    width: 30px;
    fill: currentColor;
    height: 30px;
  }
  .active-logo .router-link-exact-active {
    background-size: 100% 5px !important;
    border-radius: unset !important;
  }
  .logo a {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 6px 0;
  }
  .logo img {
    height: 40px;
  }
  .logo a .logo-texts {
    font-size: 20px;
    font-weight: 600;
    color: #282c3f;
    padding-left: 5px;
  }
  .active-class .router-link-exact-active {
    background-image: linear-gradient(90deg, #fa709a 0, #fee140);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 100%;
    transition: background-size 0.25s ease-in;
    text-decoration: none;
    border-radius: 8px;
  }
  .mob-menu {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  .mob-menu .menu {
    list-style: none;
    padding: 0px;
    margin-top: 5rem;
  }
  .mob-menu .menu > li {
    margin-bottom: 1.5rem;
  }
  .mob-menu .menu > li > a {
    text-decoration: none;
    color: #282c3f;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 5px 14px;
  }
  .mob-menu .menu > li > a svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

@media (max-width: 684px) and (min-width: 575px) {
}

@media (max-width: 767px) and (min-width: 685px) {
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 574px) and (min-width: 480px) {
}

@media (max-width: 1199px) and (min-width: 1024px) {
}
</style>
